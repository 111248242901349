<template>
  <div class="forgot-password">
    <h2>Forgot Password</h2>
    <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px" label-suffix=":">
      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email" type="text"></el-input>
      </el-form-item>
      <el-form-item class="captcha" label="Captcha" prop="captcha">
        <el-input v-model="form.captcha" type="text"></el-input>
        <span v-html="captcha"></span>
        <el-button @click="getCaptcha" icon="el-icon-refresh" circle></el-button>
      </el-form-item>
      <el-form-item>
        <el-button :loading="loading" type="primary" @click="submitForm('form')">Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      captcha: '',
      form: {
        email: '',
        captcha: '',
        captchaId: ''
      },
      rules: {
        captcha: [
          { required: true, trigger: 'blur' }
        ],
        email: [
          { required: true, trigger: 'blur' },
          // { type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  mounted: function () {
    this.getCaptcha()
  },
  methods: {
    getCaptcha () {
      this.$store.httpDispatch({})('getCaptcha').then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.captcha = data.data
          this.captchaId = data.captchaId
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    submitForm (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.httpDispatch({})('resetPasswordByEmail', {
            email: this.form.email,
            captchaId: this.captchaId,
            captcha: this.form.captcha
          }).then((res) => {
            const { code, data } = res.data
            this.loading = false
            this.getCaptcha()
            if (code === 0) {
              this.$notify.success({ title: 'Successful operation', duration: 5000 })
            } else {
              return that.$notify({
                title: data.msg,
                type: 'Error'
              })
            }
          }).catch((error) => {
            this.loading = false
            this.getCaptcha()
            console.log(error)
            that.$notify({
              title: `[${error.data.code}]:${error.data.msg}`,
              type: 'warning'
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style>
.forgot-password {
  width: 400px;
  margin: 0 auto;
  position: relative;
  top: 80px;
  padding: 50px 50px 50px;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  border: 1px solid #DFDFDF;
}
.forgot-password .el-button {
  width: 100%
}
.captcha .el-input {
  float: left;
  width: 170px;
}
.captcha .el-button {
  width: 40px;
  float: right;
}
</style>
